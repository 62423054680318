<app-breadcrumb
  [title]="page_name"
  [breadcrumb]="page_name"
  [currentUrl]="currentUrl"
></app-breadcrumb>

<section class="section-b-space position-relative checouts">
  <div class="container padding-cls">
    <div class="checkout-page">
      <div class="checkout-form">
        <form>
          <div class="row">
            <div class="col-lg-12 col-sm-12 col-xs-12">
              <div class="border-0 checkout-section checkout-details">
                <div class="loader-block" *ngIf="loading">
                  <img src="assets/images/loader.gif" />
                </div>

                <div *ngIf="!loading">
                  <div class="order-box position-relative">
                    <div
                      class="top-0 left-0 bg-transparent disable-layer position-absolute w-100 h-100 d-none"
                      #disableLayer
                    ></div>
                    <div class="cart-items-layout">
                      <div
                        *ngFor="let cartitem of cart_products; let i = index"
                        class="mb-3"
                      >
                        <mat-accordion *ngIf="products.length > 0">
                          <mat-expansion-panel
                            *ngIf="cartitem.product_items.length > 0"
                            [hideToggle]="true"
                            [(expanded)]="cartitem.panelOpenState"
                            class="my-3 bg-light"
                            style="border: 1px solid #ccc"
                          >
                            <mat-expansion-panel-header>
                              <mat-panel-title class="my-3 align-items-center">
                                <div class="logo-container">
                                  <img
                                    *ngIf="
                                      cartitem.details_store_external.id == '0'
                                    "
                                    [src]="
                                      Common._ImageUrl +
                                      'front_images/' +
                                      contactInfoData.front_fav_icon
                                    "
                                    width="40"
                                    style="padding: 4px"
                                    height="40"
                                    [border]="1"
                                    alt=""
                                    srcset=""
                                  />
                                  <img
                                    *ngIf="
                                      cartitem.details_store_external.id != '0'
                                    "
                                    [src]="
                                      importApiImage +
                                      cartitem.details_store_external?.image
                                    "
                                    width="40"
                                    style="padding: 4px"
                                    height="40"
                                    [border]="1"
                                    alt=""
                                    srcset=""
                                  />
                                  <img
                                    *ngIf="
                                      (cartitem.details_store_external.image ==
                                        null &&
                                        cartitem.details_store_external.id !=
                                          '0') ||
                                      (Common._ImageUrl +
                                        'front_images/' +
                                        contactInfoData.front_fav_icon ==
                                        null &&
                                        cartitem.details_store_external.id ==
                                          '0')
                                    "
                                    src="assets/images/product/placeholder.jpg"
                                    width="40"
                                    style="padding: 4px; margin-left: 10px"
                                    height="40"
                                    [border]="1"
                                    alt=""
                                    srcset=""
                                  />
                                </div>
                                <p
                                  class="px-2 my-auto text-black font-weight-bold price-header"
                                >
                                  {{ "order shipping" | translate }}
                                  {{
                                    cartitem.details_store_external.id == "0"
                                      ? ("Warehouse" | translate)
                                      : ""
                                  }}
                                  {{
                                    cartitem.details_store_external.id == "0"
                                      ? contactInfoData.front_name
                                      : cartitem.details_store_external.name
                                  }}
                                </p>
                              </mat-panel-title>
                              <mat-panel-description
                                class="justify-content-end"
                              >
                                <span
                                  *ngIf="cartitem.panelOpenState == true"
                                  class="d-flex bg-dark text-light see-btn"
                                  [class]="
                                    Common.isRtl
                                      ? 'see-padding-ar'
                                      : 'see-padding-en'
                                  "
                                >
                                  <span
                                    class="loader-check"
                                    *ngIf="checkLoading[i]"
                                  ></span>
                                  <span
                                    class="material-symbols-outlined loader-complete d-flex justify-content-center align-items-center"
                                    *ngIf="checkCompleted[i]"
                                  >
                                    <mat-icon>done</mat-icon>
                                  </span>
                                  <span
                                    class="material-symbols-outlined"
                                    *ngIf="
                                      !checkLoading[i] && !checkCompleted[i]
                                    "
                                  >
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                  </span>
                                  <span
                                    *ngIf="
                                      !checkLoading[i] && !checkCompleted[i]
                                    "
                                    >{{ "see less" | translate }}</span
                                  >
                                </span>
                                <span
                                  *ngIf="cartitem.panelOpenState == false"
                                  class="d-flex bg-dark text-light see-btn"
                                  [class]="
                                    Common.isRtl
                                      ? 'see-padding-ar'
                                      : 'see-padding-en'
                                  "
                                >
                                  <span
                                    class="loader-check"
                                    *ngIf="checkLoading[i]"
                                  ></span>
                                  <span
                                    class="material-symbols-outlined loader-complete"
                                    *ngIf="checkCompleted[i]"
                                  >
                                    <mat-icon>done</mat-icon>
                                  </span>
                                  <span
                                    class="material-symbols-outlined"
                                    *ngIf="
                                      !checkLoading[i] && !checkCompleted[i]
                                    "
                                  >
                                    <mat-icon>keyboard_arrow_up</mat-icon>
                                  </span>
                                  <span
                                    *ngIf="
                                      !checkLoading[i] && !checkCompleted[i]
                                    "
                                  >
                                    {{ "see more" | translate }}
                                  </span>
                                </span>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="d-flex checkout-paneContainer">
                              <!-- Billing Details -->
                              <div class="mt-1 col-lg-5 col-sm-12 col-xs-12">
                                <div
                                  class="checkout-section-inner"
                                  *ngIf="
                                    contactInfoData?.shipping_method_home_value ==
                                      '1' ||
                                    contactInfoData[
                                      'shipping_method_from_store_value'
                                    ] == '1'
                                  "
                                >
                                  <h2
                                    class="mt-3 title d-flex align-items-center"
                                  >
                                    <div
                                      class="gap-3 d-flex align-items-center"
                                    >
                                      <mat-icon
                                        class="location-icon"
                                        [ngStyle]="
                                          Common.isRtl
                                            ? { 'margin-right': '8px' }
                                            : { 'margin-left': '8px' }
                                        "
                                      >
                                        location_on
                                      </mat-icon>
                                      <span style="white-space: nowrap">{{
                                        "shipping and deliverly" | translate
                                      }}</span>
                                    </div>
                                    <!-- <div class="deliverly-line"></div> -->
                                  </h2>
                                  <div
                                    *ngIf="!userLogedIn && !deviceID"
                                    class="user_not_login"
                                  >
                                    <h3>
                                      <a
                                        (click)="goToLogin()"
                                        class="cursor-pointer"
                                      >
                                        <i class="ti-info-alt"></i> &nbsp;
                                        {{ "please login" | translate }}
                                      </a>
                                    </h3>
                                  </div>

                                  <!-- Toggle Beween Tow Div -->
                                  <div class="address-panel">
                                    <mat-accordion>
                                      <div class="top-section-panel">
                                        <mat-expansion-panel
                                          *ngIf="
                                            contactInfoData?.shipping_method_home_value ==
                                            '1'
                                          "
                                          hideToggle
                                          (click)="homeChecked(i)"
                                          [style]="{
                                            border: home.checked
                                              ? '2px solid' + myColor
                                              : ''
                                          }"
                                          style="background-color: #fff"
                                          class="pt-3"
                                          [expanded]="homeExpandeed"
                                        >
                                          <mat-expansion-panel-header>
                                            <mat-panel-title
                                              class="m-0 text-danger align-items-center"
                                            >
                                              <div
                                                class="d-flex"
                                                style="gap: 0.5rem"
                                              >
                                                <div
                                                  class="flex items-center h-5"
                                                >
                                                  <input
                                                    id="home-radio{{ i }}"
                                                    value="my_address"
                                                    [(ngModel)]="
                                                      cartitem.shippingnNum
                                                    "
                                                    name="deliveryPrice_{{ i }}"
                                                    aria-describedby="home-radio-text"
                                                    type="radio"
                                                    [attr.data-custom-index]="i"
                                                    #home
                                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                    #checked
                                                  />
                                                </div>
                                                <div class="text-sm ms-2">
                                                  <label
                                                    for="home-radio{{ i }}"
                                                    class="mb-4 text-lg text-gray-900 dark:text-gray-300"
                                                  >
                                                    {{
                                                      contactInfoData.shipping_method_home_name
                                                    }}</label
                                                  >
                                                </div>
                                              </div>
                                            </mat-panel-title>
                                          </mat-expansion-panel-header>
                                          <div class="mb-0 row check-out">
                                            <div
                                              class="layer-modal"
                                              #layer
                                              (click)="
                                                onCloseModal(modal, layer)
                                              "
                                            ></div>
                                            <div class="modal-container">
                                              <div class="container">
                                                <div class="row">
                                                  <div class="col-12">
                                                    <div class="modal" #modal>
                                                      <div
                                                        class="modal-content"
                                                        *ngIf="
                                                          this.myAddresess
                                                            .length !== 0
                                                        "
                                                      >
                                                        <div
                                                          class="header-modal d-flex align-items-center justify-content-between"
                                                        >
                                                          <h4
                                                            style="color: black"
                                                          >
                                                            {{
                                                              "saved Addresses"
                                                                | translate
                                                            }}
                                                          </h4>
                                                          <button
                                                            mat-button
                                                            (click)="
                                                              onCloseModal(
                                                                modal,
                                                                layer
                                                              )
                                                            "
                                                            style="
                                                              background: transparent;
                                                              border: none;
                                                            "
                                                          >
                                                            <mat-icon
                                                              >close</mat-icon
                                                            >
                                                          </button>
                                                        </div>
                                                        <div
                                                          class="radio-container"
                                                        >
                                                          <mat-radio-group
                                                            aria-labelledby="example-radio-group-label "
                                                            class="example-radio-group d-flex flex-column"
                                                          >
                                                            <mat-radio-button
                                                              (change)="
                                                                onChangeAddress(
                                                                  $event.value,
                                                                  i,
                                                                  modal,
                                                                  layer
                                                                )
                                                              "
                                                              (click)="
                                                                onCloseModal(
                                                                  modal,
                                                                  layer
                                                                )
                                                              "
                                                              class="example-radio-button"
                                                              value="{{
                                                                address.id
                                                              }}"
                                                              *ngFor="
                                                                let address of myAddresess;
                                                                let index = index;
                                                                let last = last
                                                              "
                                                            >
                                                              <div
                                                                class="flex-wrap d-flex justify-content-between align-items-center"
                                                              >
                                                                <div
                                                                  class="address-radio-button"
                                                                >
                                                                  <p
                                                                    class="m-0 text-danger"
                                                                    *ngIf="
                                                                      !address.user_name
                                                                    "
                                                                  >
                                                                    {{
                                                                      "name error"
                                                                        | translate
                                                                    }}
                                                                  </p>
                                                                  <p
                                                                    class="m-0"
                                                                    *ngIf="
                                                                      address.user_name
                                                                    "
                                                                  >
                                                                    {{
                                                                      address.user_name
                                                                        ? address.user_name +
                                                                          " " +
                                                                          address.last_name
                                                                        : ""
                                                                    }}
                                                                  </p>
                                                                  <p
                                                                    class="m-0"
                                                                    *ngIf="
                                                                      address.country_name ||
                                                                      address.city_name
                                                                    "
                                                                  >
                                                                    {{
                                                                      address.country_name
                                                                    }}
                                                                    ,
                                                                    {{
                                                                      address.city_name
                                                                    }}
                                                                    ,
                                                                    <span
                                                                      *ngIf="
                                                                        address.district_name
                                                                      "
                                                                      >{{
                                                                        address.district_name
                                                                      }}
                                                                    </span>
                                                                    <span
                                                                      class="m-0 text-danger"
                                                                      *ngIf="
                                                                        !address.district_name
                                                                      "
                                                                    >
                                                                      {{
                                                                        "district error"
                                                                          | translate
                                                                      }}</span
                                                                    >
                                                                    ,
                                                                    {{
                                                                      address.title
                                                                    }}
                                                                  </p>

                                                                  <p
                                                                    class="m-0"
                                                                    *ngIf="
                                                                      address.mobile
                                                                    "
                                                                  >
                                                                    {{
                                                                      address.mobile
                                                                        ? address.mobile
                                                                        : ""
                                                                    }}
                                                                  </p>
                                                                  <p
                                                                    class="m-0"
                                                                    *ngIf="
                                                                      address.mobile_2
                                                                    "
                                                                  >
                                                                    {{
                                                                      address.mobile_2
                                                                        ? address.mobile_2
                                                                        : ""
                                                                    }}
                                                                  </p>
                                                                  <p
                                                                    class="m-0 text-danger"
                                                                    *ngIf="
                                                                      !address.mobile
                                                                    "
                                                                  >
                                                                    {{
                                                                      "mobile error"
                                                                        | translate
                                                                    }}
                                                                  </p>

                                                                  <p
                                                                    class="m-0 text-danger"
                                                                    *ngIf="
                                                                      !address.user_name &&
                                                                      !address.mobile
                                                                    "
                                                                  >
                                                                    {{
                                                                      "address edit"
                                                                        | translate
                                                                    }}
                                                                  </p>
                                                                </div>
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  class="icon"
                                                                  class="ml-25"
                                                                  title="تعديل"
                                                                  (click)="
                                                                    openModal(
                                                                      index,
                                                                      'edit'
                                                                    )
                                                                  "
                                                                >
                                                                  <mat-icon
                                                                    style="
                                                                      color: rgb(
                                                                        10,
                                                                        168,
                                                                        10
                                                                      );
                                                                    "
                                                                    >edit</mat-icon
                                                                  >
                                                                </a>
                                                              </div>
                                                              <hr
                                                                style="
                                                                  margin: 10px
                                                                    0px;
                                                                "
                                                                *ngIf="!last"
                                                              />
                                                            </mat-radio-button>
                                                          </mat-radio-group>
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="py-5 d-flex justify-content-center align-items-center"
                                                        *ngIf="
                                                          this.myAddresess
                                                            .length == 0
                                                        "
                                                      >
                                                        <h4>
                                                          لا يوجد عناوين محفوظة
                                                        </h4>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              class="gap-3 form-group col-md-12 col-sm-12 col-xs-12 select-form"
                                            >
                                              <!-- <div class="field-label">{{'Shipping addresses' | translate}}
                                              <span class="text text-danger">*</span>
                                            </div> -->

                                              <button
                                                mat-icon-button
                                                class="openModal-btn"
                                                (click)="
                                                  openModalAddresses(
                                                    modal,
                                                    layer
                                                  )
                                                "
                                              >
                                                <span>{{
                                                  "address change" | translate
                                                }}</span>
                                                <mat-icon
                                                  style="
                                                    color: rgb(10, 168, 10);
                                                  "
                                                  >edit</mat-icon
                                                >
                                              </button>

                                              <div
                                                class="justify-center text d-flex align-items-center cursor"
                                              >
                                                <a
                                                  (click)="openModal(0, 'add')"
                                                  class="text"
                                                >
                                                  {{ "add" | translate }}
                                                  {{
                                                    "Shipping addresses"
                                                      | translate
                                                  }}
                                                </a>
                                                <span
                                                  class="material-symbols-outlined"
                                                  style="
                                                    font-size: 24px;
                                                    color: rgb(10, 168, 10);
                                                  "
                                                >
                                                  add
                                                </span>
                                              </div>

                                              <div
                                                *ngIf="myAddresessError[i]"
                                                class="text text-danger"
                                              >
                                                {{
                                                  "faild is required"
                                                    | translate
                                                }}
                                              </div>
                                            </div>
                                            <div
                                              class="px-3 py-1 d-flex flex-column w-100"
                                              *ngIf="
                                                cartitem.selectedAddresse !== ''
                                              "
                                            >
                                              <p
                                                class="py-2 m-0 text-danger"
                                                *ngIf="
                                                  !cartitem.selectedAddresse
                                                    .user_name
                                                "
                                              >
                                                {{ "name error" | translate }}
                                              </p>
                                              <span
                                                class="py-2 w-100"
                                                *ngIf="
                                                  cartitem.selectedAddresse
                                                    .user_name
                                                "
                                              >
                                                {{
                                                  cartitem.selectedAddresse[
                                                    "user_name"
                                                  ] +
                                                    " " +
                                                    cartitem.selectedAddresse[
                                                      "last_name"
                                                    ]
                                                }}</span
                                              >
                                              <div class="">
                                                <span class="py-2 mx-1 w-100"
                                                  >{{
                                                    cartitem.selectedAddresse[
                                                      "title"
                                                    ]
                                                  }}
                                                  ,</span
                                                >
                                                <span
                                                  class="py-2 mx-1 w-100"
                                                  *ngIf="
                                                    cartitem.selectedAddresse
                                                      .district_name
                                                  "
                                                  >{{
                                                    cartitem.selectedAddresse
                                                      .district_name
                                                  }}
                                                  ,</span
                                                >
                                                <span
                                                  class="m-0 mx-1 text-danger"
                                                  *ngIf="
                                                    !cartitem.selectedAddresse
                                                      .district_name
                                                  "
                                                  >{{
                                                    "district
                                                  error" | translate
                                                  }}
                                                  ,</span
                                                >
                                                <span class="py-2 mx-1 w-100">
                                                  {{
                                                    cartitem.selectedAddresse[
                                                      "city_name"
                                                    ]
                                                  }}
                                                  ,</span
                                                >
                                                <span class="py-2 mx-1 w-100">{{
                                                  cartitem.selectedAddresse[
                                                    "country_name"
                                                  ]
                                                }}</span>
                                              </div>
                                              <p
                                                class="py-2 m-0 text-danger"
                                                *ngIf="
                                                  !cartitem.selectedAddresse
                                                    .mobile
                                                "
                                              >
                                                {{ "mobile error" | translate }}
                                              </p>
                                              <span
                                                class="py-2 w-100"
                                                *ngIf="
                                                  cartitem.selectedAddresse
                                                    .mobile
                                                "
                                                >{{
                                                  cartitem.selectedAddresse[
                                                    "mobile"
                                                  ]
                                                }}</span
                                              >
                                              <span
                                                class="pb-2 w-100"
                                                *ngIf="
                                                  cartitem.selectedAddresse
                                                    .mobile_2
                                                "
                                                >{{
                                                  cartitem.selectedAddresse[
                                                    "mobile_2"
                                                  ]
                                                }}</span
                                              >
                                              <p
                                                class="py-2 m-0 text-danger"
                                                *ngIf="
                                                  !cartitem.selectedAddresse
                                                    .user_name ||
                                                  !cartitem.selectedAddresse
                                                    .mobile ||
                                                  !cartitem.selectedAddresse
                                                    .district_name
                                                "
                                              >
                                                {{ "address edit" | translate }}
                                              </p>
                                            </div>
                                          </div>
                                        </mat-expansion-panel>

                                        <mat-expansion-panel
                                          *ngIf="
                                            contactInfoData.shipping_method_from_store_value ==
                                            '1'
                                          "
                                          [style]="{
                                            border: store.checked
                                              ? '2px solid' + myColor
                                              : ''
                                          }"
                                          style="background-color: #fff"
                                          class="py-3 mt-3"
                                          (click)="storeChecked(i)"
                                          [expanded]="storeExpanded"
                                          #panel
                                          hideToggle
                                        >
                                          <mat-expansion-panel-header
                                            class="pb-0"
                                          >
                                            <mat-panel-title
                                              class="m-0 text-danger align-items-center"
                                            >
                                              <div
                                                class="d-flex"
                                                style="gap: 0.5rem"
                                              >
                                                <div
                                                  class="flex items-center h-5"
                                                >
                                                  <input
                                                    id="store-radio{{ i }}"
                                                    value="shipping_from_store"
                                                    [(ngModel)]="
                                                      cartitem.shippingnNum
                                                    "
                                                    name="deliveryPrice_{{ i }}"
                                                    aria-describedby="store-radio-text"
                                                    [attr.data-custom-index]="i"
                                                    type="radio"
                                                    #store
                                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                  />
                                                </div>
                                                <div class="text-sm ms-2">
                                                  <label
                                                    for="store-radio{{ i }}"
                                                    class="text-lg text-gray-900 dark:text-gray-300"
                                                  >
                                                    {{
                                                      contactInfoData.shipping_method_from_store_name
                                                    }}</label
                                                  >
                                                </div>
                                              </div>
                                            </mat-panel-title>
                                          </mat-expansion-panel-header>
                                          <div class="pb-0 row check-out">
                                            <span
                                              class="text-dark fw-bold text-note"
                                              >{{
                                                contactInfoData.delivery_times_in_store
                                              }}
                                            </span>
                                          </div>
                                        </mat-expansion-panel>
                                      </div>
                                      <div
                                        class="mt-3 form-group col-md-12 col-sm-12 col-xs-12 shipping-container"
                                        [class.d-none]="
                                          !cartitem.shippingMethodBlock
                                        "
                                        *ngIf="
                                          cartitem.selectedAddresse
                                            ?.shipping_methods?.length > 0
                                        "
                                      >
                                        <div
                                          class="field-label note-label d-flex justify-content-start align-items-center"
                                        >
                                          <span
                                            class="gap-3 d-flex align-items-center"
                                          >
                                            <mat-icon>
                                              directions_car
                                            </mat-icon>
                                            <span style="white-space: nowrap">
                                              {{
                                                "shipping company" | translate
                                              }}
                                            </span>
                                          </span>
                                        </div>

                                        <mat-radio-group
                                          aria-labelledby="select an option"
                                          class="example-radio-group d-flex flex-column"
                                        >
                                          <div
                                            class="p-1 mt-2"
                                            style="
                                              background-color: #fff;
                                              border-radius: 5px;
                                              border: 1px solid #dddddd;
                                            "
                                            *ngFor="
                                              let company of cartitem
                                                .selectedAddresse
                                                ?.shipping_methods;
                                              let first = first;
                                              let index = index
                                            "
                                            (click)="
                                              selectComapny(
                                                radioContainer,
                                                btnCompany,
                                                company,
                                                i,
                                                index
                                              )
                                            "
                                            #radioContainer
                                          >
                                            <div class="radio-container">
                                              <mat-radio-button
                                                (change)="
                                                  onChangeCompany(
                                                    $event.value,
                                                    i,
                                                    company,
                                                    radioContainer,
                                                    btnCompany,
                                                    index
                                                  )
                                                "
                                                class="example-radio-button"
                                                [value]="company.shipment_id"
                                                #btnCompany
                                              >
                                                <span>
                                                  {{ company.shipment_company }}
                                                </span>
                                              </mat-radio-button>
                                              <hr
                                                style="
                                                  margin-top: 0;
                                                  margin-bottom: 7px;
                                                "
                                              />
                                              <div
                                                style="margin: 10px 15px"
                                                *ngIf="
                                                  company.delivery_price != 0
                                                "
                                              >
                                                {{ "deliver with" | translate }}
                                                <span
                                                  *ngIf="
                                                    cartitem.minDeliveryPrice ==
                                                      undefined ||
                                                    company.default_shipping_method ==
                                                      '0'
                                                  "
                                                >
                                                  {{ company.delivery_price }}
                                                  {{
                                                    " " +
                                                      productService?.Currency
                                                        .name
                                                  }}
                                                </span>
                                                <span
                                                  *ngIf="
                                                    cartitem.minDeliveryPrice !=
                                                      undefined &&
                                                    company.default_shipping_method ==
                                                      '1'
                                                  "
                                                >
                                                  <span
                                                    style="
                                                      text-decoration: line-through;
                                                      padding-left: 10px;
                                                    "
                                                  >
                                                    {{ company.delivery_price }}
                                                    {{
                                                      " " +
                                                        productService?.Currency
                                                          .name
                                                    }}
                                                  </span>

                                                  <span
                                                    class="w-auto text-white badge badge-success"
                                                    *ngIf="
                                                      cartitem.minDeliveryPrice ==
                                                      0
                                                    "
                                                  >
                                                    {{ "free" | translate }}
                                                  </span>
                                                  <span
                                                    *ngIf="
                                                      cartitem.minDeliveryPrice >
                                                      0
                                                    "
                                                  >
                                                    {{
                                                      cartitem.minDeliveryPrice
                                                    }}
                                                    {{
                                                      productService?.Currency
                                                        .name
                                                    }}
                                                    <span
                                                      class="w-auto text-white badge badge-success"
                                                    >
                                                      {{
                                                        "discounted" | translate
                                                      }}
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                class="text-red"
                                                style="margin: 10px 15px"
                                                *ngIf="
                                                  company.delivery_price == 0
                                                "
                                              >
                                                {{
                                                  "cost_not_defined_yet"
                                                    | translate
                                                }}
                                              </div>
                                              <div style="margin: 0px 15px">
                                                <span>
                                                  {{ company.message }}
                                                </span>
                                              </div>

                                              <div
                                                *ngIf="
                                                  company.hour_shipped_orders &&
                                                  (company.delivery_time_from !=
                                                    '0' ||
                                                    company.delivery_time_to !=
                                                      '0') &&
                                                  calculateTimer(
                                                    company.hour_shipped_orders
                                                  ) &&
                                                  (calculateTimer(
                                                    company.hour_shipped_orders
                                                  )?.hours !== '00' ||
                                                    calculateTimer(
                                                      company.hour_shipped_orders
                                                    )?.minutes !== '00' ||
                                                    calculateTimer(
                                                      company.hour_shipped_orders
                                                    )?.seconds !== '00')
                                                "
                                              >
                                                <p
                                                  style="
                                                    color: var(--theme-default);
                                                    margin: 10px 14px;
                                                  "
                                                >
                                                  {{
                                                    "if you order that before"
                                                      | translate
                                                  }}
                                                  {{
                                                    calculateTimer(
                                                      company.hour_shipped_orders
                                                    )?.hours
                                                  }}
                                                  {{ "hours" | translate }}
                                                  {{
                                                    calculateTimer(
                                                      company.hour_shipped_orders
                                                    )?.minutes
                                                  }}
                                                  {{ "minutes" | translate }}
                                                  {{
                                                    calculateTimer(
                                                      company.hour_shipped_orders
                                                    )?.seconds
                                                  }}
                                                  {{ "seconds" | translate }}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </mat-radio-group>
                                      </div>

                                      <div
                                        class="mt-3 form-group col-md-12 col-sm-12 col-xs-12 shipping-container"
                                        *ngIf="
                                          contactInfoData.payment_method_home ==
                                            '1' ||
                                          contactInfoData.payment_method_bank ==
                                            '1' ||
                                          contactInfoData.payment_method_card ==
                                            '1'
                                        "
                                      >
                                        <div
                                          class="field-label note-label d-flex justify-content-start align-items-center"
                                        >
                                          <span
                                            class="gap-3 d-flex align-items-center"
                                          >
                                            <mat-icon>payment</mat-icon>
                                            <span style="white-space: nowrap">{{
                                              "payment method" | translate
                                            }}</span>
                                          </span>
                                        </div>

                                        <mat-radio-group
                                          aria-labelledby="select an option"
                                          class="example-radio-group d-flex flex-column"
                                        >
                                          <div
                                            class="p-1 mt-2"
                                            style="
                                              background-color: #fff;
                                              border-radius: 5px;
                                              border: 1px solid #dddddd;
                                            "
                                            *ngFor="
                                              let payment of paymentInfo;
                                              let index = index
                                            "
                                            (click)="
                                              selectPayment(
                                                PaymentContainer,
                                                i,
                                                btnpayment,
                                                payment.key,
                                                payment.name,
                                                payment.price,
                                                index
                                              );
                                              payment.key ===
                                              'payment_method_card'
                                                ? openPaymentModal(i)
                                                : null
                                            "
                                            #PaymentContainer
                                          >
                                            <div class="radio-container">
                                              <mat-radio-button
                                                (change)="
                                                  onChangePayment(
                                                    payment.key,
                                                    payment.name,
                                                    i,
                                                    payment.price,
                                                    PaymentContainer,
                                                    btnpayment,
                                                    index
                                                  )
                                                "
                                                class="example-radio-button w-100"
                                                #btnpayment
                                                [value]="''"
                                              >
                                                <div
                                                  class="flex-wrap d-flex justify-content-between align-items-center"
                                                >
                                                  <span>{{
                                                    payment.name
                                                  }}</span>
                                                  <div
                                                    class="gap-3 d-flex justify-content-between align-items-center"
                                                    *ngIf="
                                                      payment.key ==
                                                      'payment_method_card'
                                                    "
                                                  >
                                                    <div class="payment-logo">
                                                      <img
                                                        src="./assets/images/payment logo/mada.svg"
                                                        alt="payment-logo"
                                                      />
                                                    </div>
                                                    <div class="payment-logo">
                                                      <img
                                                        src="./assets/images/payment logo/visa.png"
                                                        alt="payment-logo"
                                                      />
                                                    </div>
                                                    <div class="payment-logo">
                                                      <img
                                                        src="./assets/images/payment logo/master.svg"
                                                        alt="payment-logo"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </mat-radio-button>
                                              <hr
                                                style="
                                                  margin-top: 0;
                                                  margin-bottom: 7px;
                                                "
                                                *ngIf="payment.text != ''"
                                              />
                                              <div
                                                style="margin: 0px 15px"
                                                class="d-flex"
                                              >
                                                <span
                                                  *ngIf="payment.text != ''"
                                                  >{{ payment.text }}</span
                                                >
                                                <span
                                                  *ngIf="+payment.price > 0"
                                                  style="margin: 0px 6px"
                                                >
                                                  {{ payment.price }}
                                                  {{
                                                    productService?.Currency
                                                      .name
                                                  }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </mat-radio-group>
                                      </div>

                                      <div
                                        class="mt-3 form-group col-md-12 col-sm-12 col-xs-12 note-container"
                                      >
                                        <div
                                          class="field-label note-label d-flex justify-content-start align-items-center"
                                        >
                                          <span
                                            class="gap-3 d-flex align-items-center"
                                          >
                                            <mat-icon> event_note </mat-icon>
                                            <span>
                                              {{ "Note" | translate }}
                                            </span>
                                          </span>
                                        </div>
                                        <textarea
                                          name="note"
                                          [value]="cartitem.note"
                                          (input)="
                                            updateNote($event.target.value, i)
                                          "
                                          placeholder="{{
                                            'note placeholder' | translate
                                          }}"
                                          class="form-control"
                                          rows="5"
                                          style="
                                            height: auto;
                                            border-radius: 5px;
                                            padding: 5px;
                                          "
                                        >
                                        </textarea>
                                      </div>
                                    </mat-accordion>
                                  </div>
                                </div>
                              </div>

                              <div class="mt-3 col-lg-7 col-sm-12 col-xs-12">
                                <h2 class="mt-1 mb-0 title">
                                  {{ "products" | translate }} <span></span>
                                </h2>
                                <div>
                                  <div
                                    class="bg-white single-product-item"
                                    style="border: 1px solid #ddd"
                                    *ngFor="let item of cartitem.product_items"
                                  >
                                    <div class="img-block">
                                      <a
                                        [routerLink]="[
                                          '/shop/product/',
                                          item.product_id,
                                          item.meta_url
                                            ? item.meta_url.split(' ').join('-')
                                            : removeBrackets(
                                                item.product_name
                                                  ? item.product_name
                                                  : ''
                                              )
                                                .split(' ')
                                                .join('-')
                                        ]"
                                        *ngIf="item.images.length > 0"
                                      >
                                        <img
                                          [src]="urlimage + item.original_image"
                                          [alt]="item?.product_name"
                                          (error)="
                                            handleImageError($event.target)
                                          "
                                          class=""
                                          *ngIf="
                                            item.images[0]['type'] === 'image'
                                          "
                                        />
                                        <img
                                          src="assets/images/video.png"
                                          *ngIf="
                                            item.images[0]['type'] === 'video'
                                          "
                                        />
                                      </a>
                                      <a
                                        [routerLink]="[
                                          '/shop/product/',
                                          item.product_id,
                                          item.meta_url
                                            ? item.meta_url
                                                ?.split(' ')
                                                .join('-')
                                            : removeBrackets(
                                                item.product_name
                                                  ? item.product_name
                                                  : ''
                                              )
                                                .split(' ')
                                                .join('-')
                                        ]"
                                        *ngIf="item.images.length == 0"
                                      >
                                        <img
                                          src="assets/images/product/placeholder.jpg"
                                        />
                                      </a>
                                    </div>
                                    <div class="item-details-block">
                                      <a
                                        class="title1"
                                        [routerLink]="[
                                          '/shop/product/',
                                          item.product_id,
                                          item.meta_url
                                            ? item.meta_url.split(' ').join('-')
                                            : removeBrackets(
                                                item.product_name
                                                  ? item.product_name
                                                  : ''
                                              )
                                                .split(' ')
                                                .join('-')
                                        ]"
                                      >
                                        {{
                                          item.product_name
                                            ? item.product_name
                                            : ("" | truncate : 60)
                                        }}
                                      </a>

                                      <div class="quantity-block">
                                        <span class="quantity-label"
                                          >{{ "quantity" | translate }}:
                                        </span>
                                        <span>
                                          {{ item.quantity }}
                                        </span>
                                      </div>
                                      <div class="price-block">
                                        <span class="price-label">
                                          {{ "Price" | translate }}:
                                        </span>
                                        <span
                                          class="last-price"
                                          *ngIf="
                                            item.last_price &&
                                            item.last_price != 0 &&
                                            item.quantity_last_price == null
                                          "
                                        >
                                          {{ item.last_price }}
                                        </span>
                                        <span
                                          class="last-price"
                                          *ngIf="+item.quantity_last_price > 0"
                                        >
                                          {{ item.quantity_last_price }}
                                        </span>
                                        <span
                                          >{{ item.price }}
                                          {{
                                            productService?.Currency.name
                                          }}</span
                                        >
                                      </div>
                                      <div class="total-price-block">
                                        <span class="total-price-label">
                                          {{ "Total-final" | translate }}:
                                        </span>
                                        <span
                                          class="last-total-price"
                                          *ngIf="
                                            item.last_price &&
                                            item.last_price != 0 &&
                                            item.quantity_last_price == null
                                          "
                                        >
                                          {{ item.last_price * item.quantity }}
                                        </span>
                                        <span
                                          class="last-total-price"
                                          *ngIf="+item.quantity_last_price > 0"
                                        >
                                          {{
                                            item.quantity_last_price *
                                              item.quantity
                                          }}
                                        </span>
                                        <span>
                                          {{ item.price * item.quantity }}
                                          {{ productService?.Currency.name }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="px-3 mt-2 bg-white border-gray-400 sub-total"
                                    style="border: 1px solid #ddd"
                                    *ngIf="cartitem.product_items.length"
                                  >
                                    <ul class="sub-total-list">
                                      <li class="checkout-total-bg">
                                        <span
                                          class="checkout-total"
                                          style="line-height: 1.1"
                                        >
                                          <b>
                                            {{
                                              cartitem["total_product_count"]
                                            }}
                                            {{ "item" | translate }} :
                                          </b>
                                        </span>
                                        <span style="font-size: 13px">
                                          <b class="fw-bolder px-2">
                                            {{ cartitem["total_price"] }}
                                            {{ productService?.Currency.name }}
                                          </b>
                                        </span>
                                      </li>

                                      <li
                                        class="td-color alpha-discount-bg"
                                        *ngIf="cartitem['total_discount'] > 0"
                                      >
                                        <span
                                          class="discount-data"
                                          [style.color]="'var(--theme-default-text)'"
                                        >
                                          <b>
                                            {{ "Discounts" | translate }}
                                          </b></span
                                        >
                                        <span
                                          class="text-black offer-attachment_single-container"
                                        >
                                          <b
                                            class="percent-discount fw-bolder px-2"
                                          >
                                            -{{
                                              Common.formatNumber(
                                                cartitem["total_discount"]
                                              )
                                            }}
                                            {{ productService?.Currency.name }}
                                          </b>
                                        </span>
                                      </li>

                                      <li
                                        class="td-color alpha-quantity-discount-bg"
                                        *ngIf="
                                          cartitem['total_quantity_discount'] >
                                          0
                                        "
                                      >
                                        <span class="quantitydiscount-tag"
                                          ><b>
                                            {{ "quantitydiscount" | translate }}
                                          </b></span
                                        >
                                        <span
                                          class="text-black offer-attachment_single-container"
                                        >
                                          <b class="fw-bolder px-2">
                                            -{{
                                              Common.formatNumber(
                                                cartitem[
                                                  "total_quantity_discount"
                                                ]
                                              )
                                            }}
                                            {{ productService?.Currency.name }}
                                          </b>
                                        </span>
                                      </li>
                                      <li
                                        class="td-color text-red"
                                        *ngIf="
                                          cartitem['total_coupon_discount'] &&
                                          cartitem['total_coupon_discount'] !=
                                            '0.0'
                                        "
                                      >
                                        <span style="font-size: 13px"
                                          ><b>
                                            {{ "coupon discount" | translate }}
                                            ({{ cartitem["couponInfo"].code }})
                                            :
                                          </b></span
                                        >
                                        <span
                                          class="offer-attachment_single-container"
                                        >
                                          <b class="fw-bolder px-2">
                                            -{{
                                              Common.formatNumber(
                                                cartitem[
                                                  "total_coupon_discount"
                                                ]
                                              )
                                            }}
                                            {{ productService?.Currency.name }}
                                          </b>
                                        </span>
                                      </li>

                                      <li
                                        class="td-color alpha-offer-bg"
                                        *ngIf="
                                          cartitem['total_offer_discount'] > 0
                                        "
                                      >
                                        <span
                                          class="offer-data"
                                          [style.color]="'var(--theme-default-text2)'"
                                          ><b>
                                            {{ "offer-discount" | translate }}
                                          </b></span
                                        >
                                        <span
                                          class="text-black offer-attachment_single-container"
                                        >
                                          <b class="fw-bolder px-2">
                                            -{{
                                              Common.formatNumber(
                                                cartitem["total_offer_discount"]
                                              )
                                            }}
                                            {{ productService?.Currency.name }}
                                          </b>
                                        </span>
                                      </li>

                                      <li
                                        class="td-color checkout-total-bg"
                                        *ngIf="cartitem.deliveryPrice != 0"
                                      >
                                        <span class="checkout-total"
                                          ><b>
                                            {{ "shipping cost" | translate }} :
                                          </b></span
                                        >
                                        <span
                                          style="font-size: 13px"
                                          *ngIf="
                                            cartitem.minDeliveryPrice ==
                                              undefined ||
                                            !cartitem.default_shipping
                                          "
                                        >
                                          <b class="fw-bolder px-2">
                                            {{ cartitem.deliveryPrice }}
                                            {{ productService?.Currency.name }}
                                          </b>
                                        </span>
                                        <span
                                          style="font-size: 13px"
                                          *ngIf="
                                            cartitem.minDeliveryPrice !=
                                              undefined &&
                                            cartitem.default_shipping
                                          "
                                        >
                                          <span
                                            style="
                                              text-decoration: line-through;
                                              padding-left: 10px;
                                            "
                                          >
                                            <b class="fw-bolder px-2">
                                              {{ cartitem.deliveryPrice }}
                                              {{
                                                productService?.Currency.name
                                              }}
                                            </b>
                                          </span>
                                          <span
                                            class="w-auto text-white badge badge-success"
                                            *ngIf="
                                              cartitem.minDeliveryPrice == 0
                                            "
                                          >
                                            {{ "free" | translate }}
                                          </span>
                                          <span
                                            *ngIf="
                                              cartitem.minDeliveryPrice > 0
                                            "
                                          >
                                            <b class="fw-bolder px-2">
                                              {{ cartitem.minDeliveryPrice }}
                                              {{
                                                productService?.Currency.name
                                              }}
                                            </b>
                                            <span
                                              class="w-auto text-white badge badge-success"
                                            >
                                              {{ "discounted" | translate }}
                                            </span>
                                          </span>
                                        </span>
                                      </li>

                                      <li
                                        class="checkout-total-bg"
                                        *ngIf="
                                          cartitem.paymentServicePrice != 0
                                        "
                                      >
                                        <span class="checkout-total"
                                          ><b>
                                            {{ "payment cost" | translate }} :
                                          </b>
                                        </span>
                                        <span style="font-size: 13px">
                                          <b class="fw-bolder px-2">
                                            {{ cartitem.paymentServicePrice }}
                                            {{ productService?.Currency.name }}
                                          </b>
                                        </span>
                                      </li>
                                      <hr />
                                      <li class="checkout-total-bg">
                                        <span class="checkout-total"
                                          ><b
                                            >{{
                                              "Total-final" | translate
                                            }}
                                            :</b
                                          ></span
                                        >
                                        <span style="font-size: 13px">
                                          <b class="fw-bolder px-2">
                                            {{
                                              cartitem["total_price"] -
                                                cartitem[
                                                  "total_coupon_discount"
                                                ] -
                                                cartitem["total_discount"] +
                                                (cartitem.minDeliveryPrice !=
                                                  undefined &&
                                                cartitem.default_shipping
                                                  ? cartitem.minDeliveryPrice
                                                  : cartitem.deliveryPrice) -
                                                cartitem[
                                                  "total_quantity_discount"
                                                ] -
                                                cartitem[
                                                  "total_offer_discount"
                                                ] +
                                                +cartitem.paymentServicePrice
                                                | number : "1.1-1"
                                            }}
                                            {{ productService?.Currency.name }}
                                          </b>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div
                                  class="privacy-details"
                                  *ngIf="privacyDetails?.pages?.length > 0"
                                >
                                  <div class="privacy-title" #privacyTitle>
                                    <h3 style="margin: 0">
                                      {{ privacyDetails?.name }}
                                    </h3>
                                  </div>
                                  <div class="pt-2 bg-white">
                                    <div
                                      class="privacy-list"
                                      *ngFor="
                                        let page of privacyDetails?.pages;
                                        let isLast = last
                                      "
                                    >
                                      <div class="item-title">
                                        <div class="d-flex align-items-start">
                                          <img
                                            [lazyLoad]="supportIcon + page.img"
                                            alt="label-icon"
                                            width="30"
                                            height="35"
                                            [defaultImage]="
                                              'assets/images/privacy.png'
                                            "
                                            width="25"
                                            height="25"
                                            alt="list-icon"
                                          />
                                          <div class="d-flex flex-column">
                                            <h6>{{ page.name }}</h6>
                                            <div
                                              class="mt-0 item-description text-muted"
                                              style="text-align: start"
                                              *ngIf="page.description"
                                            >
                                              <p>
                                                {{ page.description }}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <i
                                          *ngIf="
                                            page?.page_id !== '0' &&
                                            Common.isRtl
                                          "
                                          class="fa fa-chevron-left"
                                          aria-hidden="true"
                                          (click)="
                                            showPrivacyDetails(page?.page_id)
                                          "
                                        ></i>
                                        <i
                                          *ngIf="
                                            page?.page_id !== '0' &&
                                            !Common.isRtl
                                          "
                                          class="fa fa-chevron-right"
                                          aria-hidden="true"
                                          (click)="
                                            showPrivacyDetails(page?.page_id)
                                          "
                                        ></i>
                                      </div>
                                      <hr
                                        *ngIf="!isLast"
                                        style="
                                          margin-top: 10px;
                                          margin-bottom: -10px;
                                          border-style: dashed;
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>

                    <ul class="qty" *ngIf="!products.length">
                      <li class="empty-checkout">
                        {{ "Sorry Couldn find the product" | translate }}
                      </li>
                    </ul>
                  </div>

                  <!-- TODO   -->
                  <div class="payment-box" *ngIf="products.length">
                    <div class="mt-3 text-right d-flex justify-content-center">
                      <div class="errorOccurred" *ngIf="quantatyMorThanStor">
                        {{ "quantaty Mor Than Stor" | translate }}
                        <br />
                        <br />
                      </div>

                      <button
                        type="submit"
                        class="btn-solid btn add-invoice"
                        #btnInvoice
                        (click)="addInvoice(btnInvoice, disableLayer)"
                        [disabled]="checkBtnLoader"
                        *ngIf="payment == 'Stripe'"
                      >
                        <span>
                          {{ "confirm order" | translate }}
                        </span>
                        <span
                          class="mx-2 loader-check"
                          *ngIf="checkBtnLoader"
                        ></span>
                      </button>
                      <ngx-paypal
                        [config]="payPalConfig"
                        *ngIf="payment == 'Paypal'"
                      ></ngx-paypal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<ng-template class="theme-modal" #newsletter let-modal>
  <div class="modal-content">
    <div class="modal-body modal7" style="background-image: unset">
      <div class="p-0 container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="modal-bg">
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="offer-content" *ngIf="step1">
                <div class="circle-icon">
                  <i class="fa fa-plus"></i>
                </div>
                <h2>{{ "add" | translate }} {{ "title" | translate }}</h2>

                <br />
                <div class="row">
                  <div class="my-2 col-12">
                    <div class="inputs">
                      <p
                        class="single-input"
                        [dir]="Common.isRtl ? 'rtl' : 'ltr'"
                      >
                        <input
                          type="text"
                          class="w-100"
                          [(ngModel)]="user_name"
                          placeholder=" "
                          [dir]="Common.isRtl ? 'rtl' : 'ltr'"
                        />
                        <label [class.label-rtl]="Common.isRtl">
                          {{ "first name" | translate }}
                        </label>
                        <i class="fa fa-solid fa-user p-icon"></i>
                      </p>
                    </div>
                  </div>
                  <!-- <div class="my-2 col-6">
                    <div class="inputs">
                      <p class="single-input" [dir]="Common.isRtl?'rtl':'ltr'">
                        <input type="text" class="w-100" [(ngModel)]="last_name" placeholder=" "
                          [dir]="Common.isRtl?'rtl':'ltr'">
                        <label [class.label-rtl]="Common.isRtl">
                          {{'last name'|translate}} </label>
                        <i class="fa fa-solid fa-user p-icon"></i>
                      </p>
                    </div>
                  </div> -->
                  <p class="errorOccurred" *ngIf="successUser_name">
                    {{ errorUserNameMessage }}
                  </p>
                  <div class="my-2 col-12">
                    <div class="inputs">
                      <p
                        class="single-input mobile-p mobile-input"
                        [dir]="Common.isRtl ? 'rtl' : 'ltr'"
                      >
                        <input
                          type="tel"
                          class="w-100 form-control"
                          [(ngModel)]="mobile_data"
                          [placeholder]="phoneplaceholder"
                          (ngModelChange)="checkLength()"
                          [class.is-valid]="mobileIsValid"
                          [class.is-invalid]="mobileIsInValid"
                        />
                        <label [class.label-rtl]="Common.isRtl">
                          {{ "enter phone" | translate }}
                        </label>
                      </p>
                      <div class="mobile-code">
                        <span>{{ country_code }}+</span>
                        <img
                          [src]="image_mobile"
                          class="mb-0"
                          alt="flag-code"
                        />
                      </div>
                    </div>
                    <span class="errorOccurred" *ngIf="successMobile"
                      >{{ errorMobileMessage }}
                    </span>
                  </div>
                  <div class="my-2 col-12">
                    <div class="inputs">
                      <p
                        class="single-input mobile-p mobile-input"
                        [dir]="Common.isRtl ? 'rtl' : 'ltr'"
                      >
                        <input
                          type="tel"
                          class="w-100 form-control"
                          [(ngModel)]="mobile2_data"
                          [placeholder]="phone2placeholder"
                          (ngModelChange)="checkLength2()"
                          [class.is-valid]="mobile2IsValid"
                          [class.is-invalid]="mobile2IsInValid"
                        />
                        <label [class.label-rtl]="Common.isRtl">
                          {{ "enter phone2" | translate }}
                        </label>
                      </p>
                      <div class="mobile-code">
                        <span>{{ country_code }}+</span>
                        <img
                          [src]="image_mobile"
                          class="mb-0"
                          alt="flag-code"
                        />
                      </div>
                    </div>
                    <span class="errorOccurred" *ngIf="successMobile2"
                      >{{ errorMobileMessage2 }}
                    </span>
                  </div>
                </div>

                <div class="row">
                  <div
                    class="form-group col-md-12 col-sm-12 col-xs-12"
                    *ngIf="country_idModal != ''"
                  >
                    <div class="gap-3 d-flex">
                      <div class="mb-2 field-label">
                        {{ "City" | translate }}
                      </div>
                      <div
                        *ngIf="
                          checkoutForm.controls.city.touched &&
                          checkoutForm.controls.city.errors?.required
                        "
                        class="text text-danger"
                      >
                        {{ "faild is required" | translate }}
                      </div>
                    </div>
                    <select
                      name="city_id"
                      [(ngModel)]="city_idModal"
                      class="form-control"
                      (ngModelChange)="onCityChange(city_idModal)"
                      [formControl]="checkoutForm.controls['city']"
                    >
                      <option value="" selected>
                        {{ "city select" | translate }}
                      </option>
                      <option [value]="city.id" *ngFor="let city of cities">
                        {{ city.pla_name }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="form-group col-md-12 col-sm-12 col-xs-12"
                    *ngIf="city_idModal != ''"
                  >
                    <div class="gap-3 d-flex">
                      <div class="mb-2 field-label">
                        {{ "District" | translate }}
                      </div>
                      <div
                        *ngIf="
                          checkoutForm.controls.District.touched &&
                          checkoutForm.controls.District.errors?.required
                        "
                        class="text text-danger"
                      >
                        {{ "faild is required" | translate }}
                      </div>
                    </div>
                    <select
                      name="district_id"
                      [(ngModel)]="district_id"
                      class="form-control"
                      [formControl]="checkoutForm.controls['District']"
                    >
                      <option value="" selected>
                        {{ "district select" | translate }}
                      </option>
                      <option
                        [value]="district.id"
                        *ngFor="let district of Districts"
                      >
                        {{ district.pla_name }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <div class="gap-3 d-flex">
                      <div class="mb-2 field-label" style="text-align: start">
                        {{ "title" | translate }}
                      </div>
                      <div
                        *ngIf="
                          checkoutForm.controls.title.touched &&
                          checkoutForm.controls.title.errors?.required
                        "
                        class="text text-danger"
                      >
                        {{ "faild is required" | translate }}
                      </div>
                    </div>
                    <input
                      type="text"
                      [(ngModel)]="title"
                      name="title"
                      [formControl]="checkoutForm.controls['title']"
                      value=""
                      placeholder="{{ 'title' | translate }}"
                      autocomplete="off"
                      class="form-control"
                    />
                    <div class="mt-2 text text-info text-start">
                      {{ "Title Details" | translate }}
                    </div>

                    <div
                      *ngIf="
                        checkoutForm.controls.title.touched &&
                        checkoutForm.controls.title.errors?.maxlength
                      "
                      class="text text-danger"
                    >
                      Maximum 255 character
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                  <div class="d-flex align-items-start">
                    <label id="example-radio-group-label"
                      >{{ "default address" | translate }} :</label
                    >
                    <mat-radio-group
                      aria-labelledby="example-radio-group-label"
                      class="flex-row example-radio-group d-flex"
                      [(ngModel)]="is_default"
                      [formControl]="checkoutForm.controls['is_default']"
                    >
                      <mat-radio-button class="example-radio-button" value="1">
                        {{ "Yes" | translate }}
                      </mat-radio-button>
                      <mat-radio-button class="example-radio-button" value="0">
                        {{ "No" | translate }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <div class="p-0 border-0 modal-footer">
                  <button
                    #styledButton
                    type="button"
                    class="m-0 mr-auto btn save-btn w-100"
                    (click)="addAddress()"
                    [style.color]="'var(--theme-default-text)'"
                  >
                    {{ "save address" | translate }}
                  </button>
                </div>
              </div>
              <div class="offer-content" *ngIf="step4">
                <div class="circle-icon">
                  <i class="fa fa-plus"></i>
                </div>
                <h2>{{ "Edit" | translate }} {{ "title" | translate }}</h2>

                <br />
                <div class="row">
                  <div class="my-2 col-12">
                    <div class="inputs">
                      <p
                        class="single-input"
                        [dir]="Common.isRtl ? 'rtl' : 'ltr'"
                      >
                        <input
                          type="text"
                          class="w-100"
                          [(ngModel)]="user_name"
                          placeholder=" "
                          [dir]="Common.isRtl ? 'rtl' : 'ltr'"
                        />
                        <label [class.label-rtl]="Common.isRtl">
                          {{ "first name" | translate }}
                        </label>
                        <i class="fa fa-solid fa-user p-icon"></i>
                      </p>
                    </div>
                  </div>
                  <!-- <div class="my-2 col-6">
                    <div class="inputs">
                      <p class="single-input" [dir]="Common.isRtl?'rtl':'ltr'">
                        <input type="text" class="w-100" [(ngModel)]="last_name" placeholder=" "
                          [dir]="Common.isRtl?'rtl':'ltr'">
                        <label [class.label-rtl]="Common.isRtl">
                          {{'last name'|translate}} </label>
                        <i class="fa fa-solid fa-user p-icon"></i>
                      </p>
                    </div>
                  </div> -->
                  <p class="errorOccurred" *ngIf="successUser_name">
                    {{ errorUserNameMessage }}
                  </p>
                  <div class="my-2 col-12">
                    <div class="inputs">
                      <p
                        class="single-input mobile-p mobile-input"
                        [dir]="Common.isRtl ? 'rtl' : 'ltr'"
                      >
                        <input
                          type="tel"
                          class="w-100 form-control"
                          [(ngModel)]="mobile_data"
                          [placeholder]="phoneplaceholder"
                          (ngModelChange)="checkLength()"
                          [class.is-valid]="mobileIsValid"
                          [class.is-invalid]="mobileIsInValid"
                        />
                        <label [class.label-rtl]="Common.isRtl">
                          {{ "enter phone" | translate }}
                        </label>
                      </p>
                      <div class="mobile-code">
                        <span>{{ country_code }}+</span>
                        <img
                          [src]="image_mobile"
                          class="mb-0"
                          alt="flag-code"
                        />
                      </div>
                    </div>
                    <span class="errorOccurred" *ngIf="successMobile"
                      >{{ errorMobileMessage }}
                    </span>
                  </div>
                  <div class="my-2 col-12">
                    <div class="inputs">
                      <p
                        class="single-input mobile-p mobile-input"
                        [dir]="Common.isRtl ? 'rtl' : 'ltr'"
                      >
                        <input
                          type="tel"
                          class="w-100 form-control"
                          [(ngModel)]="mobile2_data"
                          [placeholder]="phone2placeholder"
                          (ngModelChange)="checkLength2()"
                          [class.is-valid]="mobile2IsValid"
                          [class.is-invalid]="mobile2IsInValid"
                        />
                        <label [class.label-rtl]="Common.isRtl">
                          {{ "enter phone2" | translate }}
                        </label>
                      </p>
                      <div class="mobile-code">
                        <span>{{ country_code }}+</span>
                        <img
                          [src]="image_mobile"
                          class="mb-0"
                          alt="flag-code"
                        />
                      </div>
                    </div>
                    <span class="errorOccurred" *ngIf="successMobile2"
                      >{{ errorMobileMessage2 }}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="form-group col-md-12 col-sm-12 col-xs-12"
                    *ngIf="country_idModal != ''"
                  >
                    <div class="gap-3 d-flex">
                      <div class="mb-2 field-label">
                        {{ "City" | translate }}
                      </div>
                      <div
                        *ngIf="
                          checkoutForm.controls.city.touched &&
                          checkoutForm.controls.city.errors?.required
                        "
                        class="text text-danger"
                      >
                        {{ "faild is required" | translate }}
                      </div>
                    </div>
                    <select
                      name="city_id"
                      [(ngModel)]="city_idModal"
                      class="form-control"
                      (change)="onCityChange(city_idModal)"
                      [formControl]="checkoutForm.controls['city']"
                    >
                      <option value="" selected>
                        {{ "Select" | translate }}
                      </option>
                      <option [value]="city.id" *ngFor="let city of cities">
                        {{ city.pla_name }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="form-group col-md-12 col-sm-12 col-xs-12"
                    *ngIf="city_idModal != ''"
                  >
                    <div class="gap-3 d-flex">
                      <div class="mb-2 field-label">
                        {{ "District" | translate }}
                      </div>
                      <div
                        *ngIf="
                          checkoutForm.controls.District.touched &&
                          checkoutForm.controls.District.errors?.required
                        "
                        class="text text-danger"
                      >
                        {{ "faild is required" | translate }}
                      </div>
                    </div>
                    <select
                      name="district_id"
                      [(ngModel)]="district_id"
                      class="form-control"
                      [formControl]="checkoutForm.controls['District']"
                    >
                      <option value="" selected>
                        {{ "Select district" | translate }}
                      </option>
                      <option
                        [value]="district.id"
                        *ngFor="let district of Districts"
                      >
                        {{ district.pla_name }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <div class="gap-3 d-flex">
                      <div class="mb-2 field-label" style="text-align: start">
                        {{ "title" | translate }}
                      </div>
                      <div
                        *ngIf="
                          checkoutForm.controls.title.touched &&
                          checkoutForm.controls.title.errors?.required
                        "
                        class="text text-danger"
                      >
                        {{ "faild is required" | translate }}
                      </div>
                    </div>
                    <input
                      type="text"
                      [(ngModel)]="title"
                      name="title"
                      [formControl]="checkoutForm.controls['title']"
                      value=""
                      placeholder="{{ 'title' | translate }}"
                      autocomplete="off"
                      class="form-control"
                    />
                    <div class="mt-2 text text-info text-start">
                      {{ "Title Details" | translate }}
                    </div>

                    <div
                      *ngIf="
                        checkoutForm.controls.title.touched &&
                        checkoutForm.controls.title.errors?.maxlength
                      "
                      class="text text-danger"
                    >
                      Maximum 255 character
                    </div>
                  </div>

                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <div class="d-flex align-items-start">
                      <label id="example-radio-group-label"
                        >{{ "default address" | translate }} :</label
                      >
                      <mat-radio-group
                        aria-labelledby="example-radio-group-label"
                        class="flex-row example-radio-group d-flex"
                        [(ngModel)]="is_default"
                        [formControl]="checkoutForm.controls['is_default']"
                      >
                        <mat-radio-button
                          class="example-radio-button"
                          value="1"
                        >
                          {{ "Yes" | translate }}
                        </mat-radio-button>
                        <mat-radio-button
                          class="example-radio-button"
                          value="0"
                        >
                          {{ "No" | translate }}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>

                <div class="p-0 border-0 modal-footer">
                  <button
                    #styledButton
                    type="button"
                    class="m-0 mr-auto btn save-btn w-100"
                    (click)="editAddress()"
                    [style.color]="'var(--theme-default-text)'"
                  >
                    {{ "edit address" | translate }}
                  </button>
                </div>
              </div>

              <div class="offer-content" *ngIf="step2">
                <h2 *ngIf="addStep">
                  {{ "add" | translate }} {{ "title" | translate }}
                </h2>
                <h2 *ngIf="editStep">
                  {{ "Edit" | translate }} {{ "title" | translate }}
                </h2>

                <div class="category-image">
                  <img
                    src="assets/images/slider/loader12.gif"
                    alt=""
                    class="w-auto"
                  />
                </div>

                <br />
                <div class="modal-footer">
                  <br />
                  <br />
                </div>
              </div>

              <div class="offer-content" *ngIf="step3">
                <div class="circle-icon-check">
                  <i class="fa fa-check-circle" aria-hidden="true"></i>
                </div>

                <h2 class="text-success" *ngIf="addStep">
                  {{ "success add" | translate }} {{ "title" | translate }}
                  {{ "Successfully" | translate }}
                </h2>
                <h2 class="text-success" *ngIf="editStep">
                  {{ "Edit" | translate }} {{ "title" | translate }}
                  {{ "Successfully" | translate }}
                </h2>

                <br />
                <div class="text-center border-top">
                  <br />
                  <button
                    type="button"
                    class="mr-auto btn btn-primary footer-dtn"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    {{ "button confirm" | translate }}
                  </button>
                </div>
              </div>

              <div class="offer-content" *ngIf="step11">
                <div class="circle-icon">
                  <i class="ti-info-alt"></i>
                </div>
                <h2>{{ "Delete Address" | translate }}</h2>
                <h4>
                  {{ "Do you want to" | translate }}
                  {{ "Delete Address" | translate }}
                </h4>
                <br />

                <div class="modal-footer">
                  <button
                    type="button"
                    class="mr-auto btn btn-primary"
                    (click)="deleteAddress(this.delete_address_id)"
                  >
                    {{ "Delete" | translate }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    {{ "cancel" | translate }}
                  </button>
                </div>
              </div>

              <div class="offer-content" *ngIf="step22">
                <h2>{{ "Delete Address" | translate }}</h2>

                <div class="category-image">
                  <img
                    src="assets/images/slider/loader12.gif"
                    alt=""
                    class="w-auto"
                  />
                </div>

                <br />
                <div class="modal-footer">
                  <br />
                  <br />
                </div>
              </div>

              <div class="offer-content" *ngIf="step33">
                <div class="circle-icon-check">
                  <i class="fa fa-check-circle" aria-hidden="true"></i>
                </div>

                <h2 class="text-success">
                  {{ "Delete Address" | translate }}
                  {{ "Successfully" | translate }}
                </h2>

                <br />
                <div class="modal-footer">
                  <br />
                  <button
                    type="button"
                    class="mr-auto btn btn-primary"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template class="theme-modal" #paymentlet let-modal>
  <div class="modal-content">
    <div class="modal-body modal7" style="background-image: unset">
      <div class="p-0 container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="modal-bg modal-close-payment">
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click'); resetPayment(index)"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <h3 class="mb-3 text-center text-danger w-100">
            {{ total_payment_price }} {{ productService?.Currency.name }}
          </h3>
          <ng-container *ngIf="!paymentLoading">
            <div
              class="col-12"
              *ngFor="let method of paymentMethods"
              (click)="ExecutePayment(method)"
            >
              <div class="gap-3 cursor-pointer d-flex">
                <div class="mb-3">
                  <img
                    [src]="method.ImageUrl"
                    width="40px"
                    alt="payment image"
                  />
                </div>
                <span>{{
                  Common.isRtl ? method.PaymentMethodAr : method.PaymentMethodEn
                }}</span>
              </div>
            </div>
          </ng-container>

          <div class="payment-loading" *ngIf="paymentLoading">
            <img src="assets/images/loader.gif" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template class="theme-modal" #privacylet let-modal>
  <div class="close-modal-btn">
    <div class="p-1 m-auto modal-bg d-block">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div
    class="privacy-modal overflow-auto  modal-privacy"
  >
    <div class="text-center loader-block" *ngIf="loaderSupport">
      <img src="assets/images/loader.gif" />
    </div>
    <ng-container *ngIf="!loaderSupport">
      <div class="model-header-title">
        <h6>
          {{ supportDetails?.title }}
        </h6>
      </div>
      <div
        class="modal-content-scroll"
        [innerHTML]="supportDetails?.description | sanitizeHtml : 'html'"
      ></div>
    </ng-container>
  </div>
</ng-template>

<section #login class="full-sidebar" *ngIf="loginclicked">
  <div class="shadow login-sidebar">
    <i class="ti-close login-icon" (click)="close()"></i>
    <app-fast-login></app-fast-login>
  </div>
</section>

<ng-template class="theme-modal" #successOrder let-modal>
  <div class="modal-content position-relative">
    <div class="close-modal-btn">
      <div
        class="p-1 m-auto cursor-pointer modal-bg modal-close-payment d-block"
        (click)="modal.dismiss('Cross click')"
      >
        <button type="button" class="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body modal7" style="background-image: unset">
      <div class="p-0 container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="overflow-hidden position-relative">
              <app-firework [productItems]="cartProducts"></app-firework>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
