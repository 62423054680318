import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ProductService } from "../../services/product.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { error } from "console";

@Component({
  selector: "app-similar-product-modal",
  templateUrl: "./similar-product-modal.component.html",
  styleUrls: ["./similar-product-modal.component.scss"],
})
export class SimilarProductModalComponent {
   product_id: string = "";
  @Output() modalClosed = new EventEmitter<void>();

  @ViewChild("templateModalSimilarProduct")
  templateModalSimilarProduct!: TemplateRef<any>;

  modalRef!: NgbModalRef;
  formShapesLoading = false;
  finished = false;
  all_products: any[] = [];
  start = 0;
  limit = 20;
  loadData = true;
  noContent = false;
  productName : string = '';
  loggedIn = true;

  constructor(
    private modalService: NgbModal,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {}

  openModal(id: string , name:string = '') {
    console.log('name', name);
    this.productName = name;
    this.initiateNotifyMeForm();
    this.getEmailFromLocalstorage();
    this.product_id = id;
    this.formShapesLoading = true;
    this.getReleatedProductFormShapes();
    document.querySelector("html")?.classList.add("no-scroll");

    this.modalRef = this.modalService.open(this.templateModalSimilarProduct, {
      size: "xl",
      ariaLabelledBy: "template-similar-product-Modal",
      windowClass: "shipping-modal modal-support no-scroll modal-height",
      centered: true,
      scrollable: true,
      keyboard: true,
      backdrop: true,
    });

    setTimeout(() => this.attachScrollListener(), 500);

    this.modalRef.result.finally(() => {
      document.querySelector("html")?.classList.remove("no-scroll");
      this.detachScrollListener();
      this.modalClosed.emit();
    });
  }

  closeModal() {
    const scrollPosition = window.scrollY;

    if (this.modalRef) {
      this.modalRef.dismiss();
      this.all_products = [];
      this.noContent = false;
      this.finished = false;
      this.start = 0;
    }

    setTimeout(() => {
      window.scrollTo({ top: scrollPosition });
    }, 0);
  }

  attachScrollListener() {
    const modalContent = document.querySelector(".modal-body");
    modalContent?.addEventListener("scroll", this.onScroll);
  }

  detachScrollListener() {
    const modalContent = document.querySelector(".modal-body");
    modalContent?.removeEventListener("scroll", this.onScroll);
  }

  onScroll = (event: Event) => {
    const modalContent = event.target as HTMLElement;
    if (!modalContent) return;

    const nearBottom =
      modalContent.scrollTop + modalContent.clientHeight >=
      modalContent.scrollHeight - 50;
    if (
      nearBottom &&
      !this.formShapesLoading &&
      !this.finished &&
      this.loadData
    ) {
      this.getReleatedProductFormShapes();
    }
  };

  getReleatedProductFormShapes() {
    if (this.noContent) return;
    if (this.finished) return;
    this.loadData = false;
    if (this.start === 0) {
      this.all_products = [];
      this.formShapesLoading = true;
    }

    this.productService
    .getReleatedProductFormShapes(
      this.product_id,
      {},
      this.limit,
      this.start,
      "",
      ""
    )
    .subscribe({
      next:(products) => {
        if (products.data) {
          if (!products.data || products?.data.length === 0) {
            this.finished = true;
          } else {
            this.all_products = [...this.all_products, ...products.data];
            this.start += this.limit;
            this.finished = products?.data.length < this.limit;
          }
          this.formShapesLoading = false;
          this.loadData = true;
        }else{
          this.finished = true;
          this.noContent = true;
          this.initiateNotifyMeForm();
          this.getEmailFromLocalstorage();
          console.log(this.noContent);
        }

      },
      error:err=>{
        this.noContent = true;
        this.initiateNotifyMeForm();
        this.getEmailFromLocalstorage();
        console.log(this.noContent);
      }

    });
  }


  //? notify 

  notifyMeForm!: FormGroup;
  email = "";


  initiateNotifyMeForm(){
    this.notifyMeForm = this.formBuilder.group({
      // email: ["", [Validators.required, Validators.email]],
      note: ["", []],
    });
  }

  getEmailFromLocalstorage() {
    if (localStorage.getItem("currentUserFront")) {
      let currentUserFront1 = localStorage.getItem("currentUserFront");
      currentUserFront1 = JSON.parse(currentUserFront1);

      if (currentUserFront1["email"]) {
        
        this.email = currentUserFront1["email"];
        // this.notifyMeForm.controls["email"].setValue(this.email);
      }
    }else{
      this.loggedIn = false;
    }
  }


  confirmNotifyMeModal() {
    if(this.notifyMeForm.valid){
      const notifyMeText1 = this.notifyMeForm.controls['note'].value;
      // const emailAddress = this.notifyMeForm.controls['email'].value;
      const emailAddress = this.email;

      this.modalService.dismissAll();

      this.productService
        .listenToProductAvailability(
          Number(this.product_id),
          notifyMeText1,
          emailAddress
        )
        .subscribe({
          next: (result) => {
            if (result.success) {
              const successMessage = this.translateService.instant(
                "You Will Be Notified When The Product Is Available"
              );
              this.toastrService.success(successMessage);
            } else {
              this.toastrService.error(result.message);
            }
          },
          error: (error) => {
            this.toastrService.error(
              this.translateService.instant(
                "some error occured, Please Try again Later"
              )
            );
          },
        });
      
    }else{
      console.log('this form is not validd', this.notifyMeForm.controls["email"].errors);
    }

    
  }

}
