import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: "app-notify-me-modal",
  templateUrl: "./notify-me-modal.component.html",
  styleUrls: ["./notify-me-modal.component.scss"],
})
export class NotifyMeModalComponent implements OnInit {
  notifyMeForm!: FormGroup;
  
  email = "";
  @Input() productIdToListenToAvailability: number;
  loggedIn: boolean = true;

  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initiateNotifyMeForm();
    this.getEmailFromLocalstorage();
    
  }

  initiateNotifyMeForm(){
    this.notifyMeForm = this.formBuilder.group({
      // email: ["", [Validators.required, Validators.email]],
      note: ["", []],
    });
  }

  getEmailFromLocalstorage() {
    if (localStorage.getItem("currentUserFront")) {
      let currentUserFront1 = localStorage.getItem("currentUserFront");
      currentUserFront1 = JSON.parse(currentUserFront1);

      if (currentUserFront1["email"]) {
        
        this.email = currentUserFront1["email"];
        // this.notifyMeForm.controls["email"].setValue(this.email);
      }
    }else{
      this.loggedIn = false;
    }
  }

  dismissAllModal() {
    this.modalService.dismissAll();
  }

  confirmNotifyMeModal() {
    if(this.notifyMeForm.valid){
      const notifyMeText1 = this.notifyMeForm.controls['note'].value;
      // const emailAddress = this.notifyMeForm.controls['email'].value;
      const emailAddress = this.email;

      this.modalService.dismissAll();

      this.productService
        .listenToProductAvailability(
          this.productIdToListenToAvailability,
          notifyMeText1,
          emailAddress
        )
        .subscribe({
          next: (result) => {
            if (result.success) {
              const successMessage = this.translateService.instant(
                "You Will Be Notified When The Product Is Available"
              );
              this.toastrService.success(successMessage);
            } else {
              this.toastrService.error(result.message);
            }
          },
          error: (error) => {
            this.toastrService.error(
              this.translateService.instant(
                "some error occured, Please Try again Later"
              )
            );
          },
        });
      
    }else{
      console.log('this form is not validd', this.notifyMeForm.controls["email"].errors);
    }

    
  }
}
