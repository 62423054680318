<ng-template #templateModalSimilarProduct let-modal>
    <div class="close-modal-btn">
      <div class="p-1 m-auto modal-bg d-block cursor-pointer" (click)="closeModal()">
        <button type="button" class="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="privacy-modal overflow-auto modal-privacy">
      <div class="modal-header justify-content-center">
        <h4 class="m-0 text-center " style="line-height: 1.5;" >
          <span class="product-name">
            {{productName}} <span style="color:red;" >"{{'out of stock' | translate}}"</span>
          </span>
          <br>
          <span class="font-weight-bold" style="color:red;" >
            {{ 'similar_or_alternative_items' | translate }}
          </span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="text-center loader-block" *ngIf="formShapesLoading && !noContent">
          <img src="assets/images/loader.gif" />
        </div>
        
        <ng-container  *ngIf="noContent && all_products.length == 0">
          <form [formGroup]="notifyMeForm" class="notify-me-modal">
            <div class="modal-body">
              <h4 class="m-0 mb-4" style="line-height: 1.5;" >
                <span style="color:red;" >
                  {{ 'no_similar_products' | translate }} ,
                </span>
                {{ 'modal_header_similar_no_content' | translate }}
              </h4>
                <!-- <div class="form-group d-flex flex-column align-items-start">
                    <label for="email">{{'Email' | translate}}</label>
                    <input
                        [ngClass]="notifyMeForm.get('email').invalid && (notifyMeForm.get('email').dirty ||
                        notifyMeForm.get('email').touched) ? 'error-class' : ''"
                        type="email" class="form-control" formControlName="email" id="email"
                        placeholder="{{'Please Enter Your Email Address' | translate}}" required=""
                        name="email">
                    <div class="error-messages-block">
                        <span  *ngIf="notifyMeForm.get('email').hasError('required') && (notifyMeForm.get('email').dirty || notifyMeForm.get('email').touched)">{{'email is required' | translate}}</span>
                        <span  *ngIf="notifyMeForm.get('email').hasError('email') && (notifyMeForm.get('email').dirty || notifyMeForm.get('email').touched)">{{'email is not valid' | translate}}</span>
                    </div>
                    
                </div> -->
        
                <div class="form-group d-flex flex-column align-items-start">
                    <label for="note">{{'Note' | translate}}</label>
                    <textarea name="note" class="text-area-1" formControlName="note" rows="4" placeholder="{{'Here You can write your notes about this product. EX: Requested Quantity' | translate}}"></textarea>
                </div>
        
                
        
            </div>
            <div class="modal-footer border-0">
                <button type="submit" class="btn btn-outline-dark confirm-button"
                    (click)="confirmNotifyMeModal()">{{"confirm" | translate}}</button>
            </div>
        </form>
        </ng-container>
        <div class="grid-view" *ngIf="!formShapesLoading">
          <div class="row" *ngIf="all_products.length > 0">
            <div class="col-grid-box product-col-container1" style="margin: 5px"
                 *ngFor="let product of all_products" (click)="closeModal()" >
              <app-product-box-one
                [product]="product"
                [thumbnail]="false"
                [cartModal]="true">
              </app-product-box-one>
            </div>
          </div>
          <div class="col-sm-12 text-center section-b-space mt-5 no-found"
               *ngIf="!all_products.length && finished">
            <img src="assets/images/empty-search1.png" class="img-fluid mb-4" />
            <h3>{{ "Sorry Couldn find the product" | translate }}</h3>
          </div>
          <div class="d-flex justify-content-center" *ngIf="all_products.length > 0">
            <img src="assets/images/loader.gif" *ngIf="!finished" />
            <div class="loading-more active" *ngIf="finished && all_products.length">
              {{ "No More Products" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>


  