<div
  class="slider-container"
  [ngStyle]="{
    height: itemHeight + 'px'
  }"
>
  <div class="slider-content" #sliderContent>
    <span
      class="slider-item"
      *ngFor="let item of items; let i = index"
      [ngStyle]="{
        'background-color': item.tag_color,
        padding: item.tag_color ? '0 5px' : '0px',
        height: itemHeight + 'px'
      }"
      [ngClass]="CommonService.isRtl ? 'text-right' : 'text-left'"
    >
      <img
        *ngIf="item.img"
        [src]="CommonService._ImageUrl + 'tags/' + item.img"
        [alt]="item.tag_value"
        [ngStyle]="{
          height: itemHeight - 2 + 'px'
        }"
      />
      <p
        [ngStyle]="{
          'font-size': itemStyle['font-size'],
          'font-weight': itemStyle['font-weight'],
          'line-height': itemHeight + 'px',
          'color': item.tag_color_text ? item.tag_color_text : '#000'
        }"
      >
        {{ item.tag_value }}
      </p>
    </span>
  </div>
</div>



<!-- swiper -->

<!-- 

 <swiper
 [config]="similarProductsSliderConfig"
 direction="v"
  [pagination]="false"
  class=""
  #itemsSwiper
>
  <ng-template
    class="h-100"
    *ngFor="let item of items; let i = index"
    swiperSlide
    let-data
  >
  
    <div class="slider-content" #sliderContent>
    <span
      class="slider-item"
      [ngStyle]="{
        'background-color': item.tag_color,
        padding: item.tag_color ? '0 5px' : '0px',
        height: itemHeight + 'px'
      }"
      [ngClass]="CommonService.isRtl ? 'text-right' : 'text-left'"
    >
      <img
        *ngIf="item.img"
        [src]="CommonService._ImageUrl + 'tags/' + item.img"
        [alt]="item.tag_value"
        [ngStyle]="{
          height: itemHeight - 2 + 'px'
        }"
      />
      <p
        [ngStyle]="{
          'font-size': itemStyle['font-size'],
          'font-weight': itemStyle['font-weight'],
          'line-height': itemHeight + 'px',
          'color': item.tag_color_text ? item.tag_color_text : '#000'
        }"
      >
        {{ item.tag_value }}
      </p>
    </span>
  </div>

  </ng-template>
</swiper>
-->
