import { getMessaging, getToken } from "firebase/messaging";

import { Injectable, Inject, EventEmitter, Output } from "@angular/core";

import { Router } from "@angular/router";
import { Observable, of } from "rxjs";

import { HttpClient, HttpInterceptor } from "@angular/common/http";
import { Title, Meta } from "@angular/platform-browser";
// import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "./common.service";
import { environment } from "../../../environments/environment";
import { catchError } from "rxjs/operators";

declare var jquery: any;
declare var $: any;

@Injectable({
  providedIn: "root",
})
export class UserService {
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  private _url: string = this.common._hostName + "users.php?action=";
  constructor(
    private router: Router,
    private common: CommonService,
    private http: HttpClient
  ) {}

  userSignUp(data) {
    //   console.log("userSignUp",data);
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + "signUp", body);
  }

  // To Share Cart List into User Cart
  shareCartList() {
    return this.http.get<any>(this._url + "mergeCart");
  }

  addAddress(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + "addAddess", body);
  }
  editAddress(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + "editAddess", body);
  }

  deleteAddress(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + "deleteAddress", body);
  }

  userEditProfile(data) {
    //   console.log("userSignUp",data);
    let body = JSON.stringify(data);

    return this.http.post<any>(this._url + "editProfile", body);
  }

  userLogin(data) {
    let body = JSON.stringify(data);

    return this.http.post<any>(this._url + "login", body);
  }
  userForgetPassword(data) {
    let body = JSON.stringify(data);

    return this.http.post<any>(this._url + "forgetPassword", body);
  }

  userLoggedIn(name) {
    this.getLoggedInName.emit(name);
  }
  logout(): void {
    this.getLoggedInName.emit("");
  }

  getToken() {
    return localStorage.getItem("clientToken");
  }
  getShowPrice() {
    return localStorage.getItem("show_price");
  }

  getCurrentUser() {
    return localStorage.getItem("currentUserFront");
  }

  addVisite() {
    return this.http.post<any>(this._url + "addVisit", null);
  }
  getAllAddress() {
    return this.http.get<any>(
      this._url + "getAllAddress&start=0&aItemsPerPage=500"
    );
  }
  
  getUserToken() {
    var token: any =
      localStorage.getItem("clientToken") != null
        ? localStorage.getItem("clientToken")
        : "";
    return token;
  }

  getDeviceId() {
    // return localStorage.getItem("Device-Id") ? localStorage.getItem("Device-Id") : this.getDeviceTokenFromFirebase();
    return localStorage.getItem("Device-Id")
      ? localStorage.getItem("Device-Id")
      : "";
  }

  // getDeviceTokenFromFirebase() {
  //   const message = getMessaging();
  //   getToken(message, { vapidKey: environment.firebase.vapidKey }).then(success => {
  //     localStorage.setItem("Device-Id", success);
  //   }).catch(err => {
  //     console.log(err);
  //   });
  // }

  cheackLoginByParams(body) {
    return this.http.post<any>(this._url + "cheackLoginByParams ", body);
  }

  checkCode(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + "checkVerifyCode", body);
  }
  
  forgetPasswordUserEmail(data)
  {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + "forgetPasswordUserEmail", body);
  }
}
