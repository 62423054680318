import { CommonService } from "src/app/shared/services/common.service";
import {
  Component,
  ElementRef,
  AfterViewInit,
  ViewChild,
  Input,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { SwiperComponent } from "swiper/angular";
import { SwiperOptions } from "swiper";

@Component({
  selector: "app-item-slider",
  templateUrl: "./item-slider.component.html",
  styleUrls: ["./item-slider.component.scss"],
})
export class ItemSliderComponent implements AfterViewInit {
  // ?Swiper
  // @ViewChild("itemsSwiper") itemsSwiper?: SwiperComponent;
  // similarProductsSliderConfig: SwiperOptions = {
  //   // direction: 'vertical',
  //   slidesPerView: 1,
  //   loop: true,
  // };
  
  @ViewChild("sliderContent", { static: true }) sliderContent!: ElementRef;
  @Input() items: any[] = [];
  @Input() itemStyle: { [key: string]: string } = {
    "font-size": "12px",
    "font-weight": "400",
  };
  @Input() itemHeight = 24;
  animationSpeed = 500;
  delayBetweenSlides = 2000;
  currentIndex = 1;
  timer: NodeJS.Timeout;
  timeoutTimer: NodeJS.Timeout;

  constructor(
    public CommonService: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngAfterViewInit(): void {
    clearInterval(this.timer);
    clearTimeout(this.timeoutTimer);
    if (isPlatformBrowser(this.platformId)) {
      if (this.items.length <= 1) return;
      console.log('items', this.items);
      
      const slider = this.sliderContent.nativeElement;
      const firstClone = slider.firstElementChild.cloneNode(true);
      const lastClone = slider.lastElementChild.cloneNode(true);
  
      slider.appendChild(firstClone); 
      slider.insertBefore(lastClone, slider.firstElementChild); 

      slider.style.transition = "none";
      slider.style.transform = `translateY(-${this.currentIndex * this.itemHeight}px)`;
  
      this.timeoutTimer = setTimeout(() => {
        slider.style.transition = `transform ${this.animationSpeed}ms ease-in-out`;
        this.timer = setInterval(() => this.updateSlider(), this.delayBetweenSlides);
      }, 100);
    }
  }

  updateSlider(): void {
    const slider = this.sliderContent.nativeElement;
    this.currentIndex++;

    slider.style.transition = `transform ${this.animationSpeed}ms ease-in-out`;
    slider.style.transform = `translateY(-${this.currentIndex * this.itemHeight}px)`;

    if (this.currentIndex === this.items.length + 1) {
      setTimeout(() => {
        slider.style.transition = "none";
        this.currentIndex = 1;
        slider.style.transform = `translateY(-${this.currentIndex * this.itemHeight}px)`;
      }, this.animationSpeed);
    }
  }

  // calculateTextColor(themeVariable): string {
  //   return this.CommonService.tinyColor(themeVariable);
  // }

  ngDestroy() {
    clearInterval(this.timer);
    clearTimeout(this.timeoutTimer);
  }
}